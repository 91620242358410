import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Button from 'app/components/common/formik/Button';
import LabImage from 'images/LabImage.png';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import Divider from '@setproduct-ui/core/Divider';
import { CustomDialogCloseIcon } from 'app/components/common/NewDesignSystem/CustomDialogCloseIcon';
import Input from 'app/components/common/formik/Input';
import FormField from 'app/components/common/NewDesignSystem/FormField';
import { getCSRF } from 'app/api';
import axios from 'axios';
import { RegistrationSchemaLabEmail } from 'app/helpers/validators';
import { hardRedirectRequested, loginWithPassword, NOOP } from 'app/actions/customer';
import * as Routes from 'app/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import * as routerSelectors from 'app/selectors/router';
import { LaaSType } from 'app/constants/Products';

const ModalBodyBiomarkers = ({ setShowModal, base_t_kit }) => (
  <div className="modal-body new-design-system">
    <p className="description">
      We analyze these key biomarkers to give you a complete picture of your testosterone health:
    </p>
    <ul>
      <li>
        <b className="bold">Free Testosterone</b>: Active testosterone your body can actually use.
      </li>
      <li>
        <b className="bold">Total Testosterone</b>: Total amount of testosterone in your body, both active and inactive.
      </li>
      <li>
        <b className="bold">SHBG (Sex Hormone-Binding Globulin)</b>: Protein that binds to testosterone, affecting how
        much is available for your body to use.
      </li>
      <li>
        <b className="bold">E2 (estradiol)</b>: Form of estrogen that helps balance testosterone and overall hormonal
        health.
      </li>
      <li>
        <b className="bold">LH (luteinizing hormone)</b>: Regulates testosterone production.
      </li>
      <li>
        <b className="bold">FSH (Follicle-Stimulating Hormone)</b>: Important for sperm production and reproductive
        health.
      </li>
      {base_t_kit && (
        <li>
          <b className="bold">TPSA</b>: Measures prostate-specific antigen to monitor prostate health.
        </li>
      )}

      {!base_t_kit && (
        <li>
          <b className="bold">PSA (Prostate-Specific Antigen)</b>: Marker used to monitor prostate health.
        </li>
      )}
      <li>
        <b className="bold">ALT</b>: Liver enzyme that helps ensure your liver is functioning properly.
      </li>
      {!base_t_kit && (
        <li>
          <b className="bold">GGT (Gamma-Glutamyl Transferase)</b>: Liver enzyme involved in detoxification and bile
          flow; often elevated in liver stress or bile duct issues.
        </li>
      )}
      <li>
        <b className="bold">Vitamin D</b>: Plays a role in testosterone production and overall energy levels.
      </li>
      {!base_t_kit && (
        <>
          <li>
            <b className="bold">Hematocrit</b>: Measures the percentage of red blood cells in your blood, which helps
            carry oxygen throughout your body.
          </li>
          <li>
            <b className="bold">Creatinine</b>: By-product of muscle metabolism that helps assess kidney function.
          </li>
          <li>
            <b className="bold">HbA1c</b>: Three-month average of blood sugar levels, used to monitor glucose control.
          </li>
        </>
      )}
    </ul>
    <Button
      style="primary"
      type="button"
      data-testid="modal-biomarkers-got-it"
      text="Got It"
      className="button-next"
      disabled={false}
      onClick={() => setShowModal(false)}
    />
  </div>
);

const EnterEmailStep = ({ initialValues, onSubmitNextStep }) => {
  const msoTermsOfUseLink = '/terms-of-use';
  const msoPrivacyPolicyLink = '/privacy-policy';
  const [showModal, setShowModal] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [onlyLabEligible, setOnlyLabEligible] = useState(false);
  const dispatch = useDispatch();
  const sub_product = useSelector((state) => routerSelectors.selectRouterQuery(state).get('sub_product'));

  const base_t_kit = sub_product == LaaSType.BaseTestKit;

  const onSubmit = async (values, form) => {
    if (!showPasswordField) {
      const response = await axios.post(
        '/api/guests/validate_email_uniqueness',
        {
          email: values.email,
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      );

      setEmailExist(!response.data.unique);
      setOnlyLabEligible(response.data.only_lab_eligible);

      if (response.data.unique) {
        onSubmitNextStep(values);
      }
    } else {
      const action = loginWithPassword({
        params: {
          ...values,
          anonymous_id: window.analytics?.user?.()?.anonymousId() || null,
        },
        context: {
          onSuccessActionCreator: ({ json: { path } }) =>
            onlyLabEligible
              ? hardRedirectRequested(
                  sub_product ? `${Routes.LabCheckout}?sub_product=${sub_product}` : Routes.LabCheckout,
                )
              : hardRedirectRequested(path + '?show_only_lab_modal=true'),
          onFailureActionCreator: ({ json: { error } }) => {
            if (error === 'invalid') {
              form.setFieldError('password', 'Invalid password.');
            } else {
              form.setErrors({ api: 'Internal error' });
            }

            return { type: NOOP };
          },
        },
      });

      await dispatch(action);
    }
  };

  const renderBiomarkersModal = () => (
    <SetProductDialog
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      title={<h3 className="title">Biomarkers We Test</h3>}
      text={<ModalBodyBiomarkers setShowModal={setShowModal} base_t_kit={base_t_kit} />}
      className="align-left"
      showCloseModalIcon={false}
      icon={<CustomDialogCloseIcon onClose={() => setShowModal(false)} />}
    />
  );

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={RegistrationSchemaLabEmail}
    >
      {({ isSubmitting, setFieldValue, isValid }) => (
        <Form>
          <div className="product-image">
            <img src={LabImage} />
          </div>
          <h3 className="title mb-8">Testosterone {base_t_kit ? 'Base' : 'Advanced'} Test</h3>
          <p className="description mb8">
            Your <b className="bold">Testosterone Test Kit</b> arrives fast with free Priority Shipping, so you can
            check your key hormones quickly and accurately.
          </p>

          <a href="#" onClick={() => setShowModal(true)}>
            Learn about biomarkers we test for
          </a>

          <Divider className="mt24 mb24" />

          <p className="description">
            Please enter your email address which will allow you to access your results quickly.
          </p>

          <div className="inputs__item">
            <FormField label={'Email Address'}>
              <Input
                onChange={(e) => setFieldValue('email', e.target.value.trim())}
                id="email"
                name="email"
                placeholder="Enter Email Address"
                className="text-field"
                disabled={emailExist}
              />
              {emailExist && !showPasswordField && (
                <div className="exist">
                  This email already exists. Would you like to <a onClick={() => setShowPasswordField(true)}>Log in</a>?
                </div>
              )}
            </FormField>
            {showPasswordField && (
              <FormField label={'Password'}>
                <Input
                  onChange={(e) => setFieldValue('password', e.target.value.trim())}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  className="text-field"
                />
              </FormField>
            )}
          </div>

          <Button
            disabled={isSubmitting || !isValid || (emailExist && !showPasswordField)}
            style="primary"
            type="submit"
            data-testid="registration-step-0-submit"
            text={showPasswordField ? 'Log In' : 'Next'}
            className="button-next"
          />

          <p className="hints mt24">
            By clicking this button, I acknowledge that I have read, understand, and agree to the{' '}
            <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
              Terms of Use
            </a>
            {' and '}
            <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          {renderBiomarkersModal()}
        </Form>
      )}
    </Formik>
  );
};

export default EnterEmailStep;
